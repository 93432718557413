import { Outlet, Link } from "react-router-dom";

const Layout = () => {
  return (
    <>
    
  <div className="topbar py-3" style={{backgroundColor: '#F26665'}}></div>
  <header id="header" className="d-flex align-items-center">
    
	<div className="container d-flex align-items-center justify-content-between">

      <div className="logo">
        <Link to="/"><img src="assets/img/imgs/logo.svg" alt="" className="img-fluid"/></Link>
      </div>

      <nav id="navbar" className="navbar">
        <ul>
          <li><Link className="nav-link scrollto active" to="/">Home</Link></li>
          <li> <Link className="nav-link scrollto active" to="/reviews">Reviews</Link></li>
          <li><Link className="nav-link scrollto active" to="/contactus">Contact us</Link></li>
          {/*<li><Link className="nav-link scrollto active" to="/privacypolicy">Privacy Policy</Link></li>
          <li><Link className="nav-link scrollto active" to="/terms">Terms</Link></li>*/ }
        </ul>
      </nav>
	  {/* <ul className="header-icons list-unstyled mb-0 d-flex">
        <li><a className="nav-link scrollto" href="#"><i className="bi bi-search"></i></a></li>
        <li><a className="nav-link scrollto" href="#"><i className="bi bi-person"></i></a></li>
        <li><a className="nav-link scrollto" href="# "><i className="bi bi-printer"></i></a></li>
      </ul>
      <i className="bi bi-list mobile-nav-toggle"></i>*/}
      

    </div>
  </header>

      <Outlet />
	  <footer id="footer" className="p-0">

    <div hidden className="footer-newsletter">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h4>Subscribe to our Newsletter</h4>

            <form>
              <input type="email" name="email"/><input type="submit" value="Subscribe"/>
            </form>

            <p className="mt-3">Get the latest updates on the new products! Subscribe to our newsletter now.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="footer-top">
      <div className="container">
        <div className="row">

          <div className="footer-contact footer-links text-center">
            <div className="logo mb-3">
              <a href="/"><img src="assets/img/imgs/white-logo.svg" alt="" className="img-fluid"/></a>
            </div>
            <p>
            
            </p>
            <ul>
              <li> <Link to="/">Home</Link></li>
			  {/* <li> <Link to="clinicalstudies">Clinical Studies</Link></li>*/ }
              <li> <Link to="reviews">Reviews</Link></li>
              <li> <Link to="contactus">Contact us</Link></li>
			  <li><Link className="" to="/privacypolicy">Privacy Policy</Link></li>
          <li><Link className="" to="/terms">Terms</Link></li>

            </ul>
            <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
            <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
            <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
          </div>

        </div>
      </div>
    </div>

  </footer>
    </>
  )
};

export default Layout;