const Contact = () => {
  return   	<main id="main"><section id="hero" className="d-flex align-items-center">

    <div className="container">
      <div className="row gy-4">
        <div className="col-md-4 hero-img">
          <img src="assets/img/imgs/dr.svg" className="img-fluid" alt=""/>
        </div>
        <div className="col-md-4 d-flex flex-column justify-content-center pt-5 align-items-start">
          <img src="assets/img/imgs/Dr.Ajayita-sign.svg" height="78" className=" " alt=""/>
          <h3 className="mt-2 text-center">BAMS, Gold Medalist, HP University, Shimla</h3>

        </div>
        <div className="col-md-4 d-flex flex-column justify-content-center">
          <h1 className="text-uppercase">Economic
            Times
            Punjab
            Icon of
            2022</h1>
        </div>

      </div>
    </div>

	</section>


    <section id="about" className="about">
      <div className="container">

        <div className="row justify-content-between">
          <div className="col-lg-4 d-flex align-items-center justify-content-center about-img col-md-4">
            <img src="assets/img/imgs/dr-second-img.png" className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-8 pt-5 pt-lg-0 d-flex align-items-center col-md-8">
            <div className="px-5 sec-bg d-flex align-items-center">
              <div className="">
                <h3 className="h3">Take Online Doctor Appointment</h3>
                <p className="p mb-4">All these products are based on Pure Ancient Ayurvedic texts researched & written by
                  our Rishis.</p>
                <a href="#" className="btn rounded-0 tratement-btn me-3">Dr. Ajayita Treatment</a>
                <a href="https://www.jamun.com/home2" className="btn rounded-0 consult-btn">Book Dr. Appointment </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>{/* End About Section */}
      </main>;
};

export default Contact;