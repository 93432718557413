const Iredcheckout = () => {
  return  <table class="table">
										<tbody>
										<tr>
											<td style={{border:"none"}} colspan="2"><b id="n409_512">Secure Checkout</b></td>
										</tr>
										<tr>
										<td>
												<div class="col-md-2">
														<select class="form-control input-sm select-sm" onchange="this.form.submit()" id="quantity" name="qty">
															<option value="1">1</option>
															<option value="2" >2</option>
															<option value="3" >3</option>
															<option value="4" >4</option>
															<option value="5" >5</option>
															<option value="6" >6</option>
															<option value="7" >7</option>
															<option value="8" >8</option>
															<option value="9" >9</option>
															<option value="10" >10</option>
														</select>
												</div>
											</td>
										</tr>
										<tr id="iredml">
											<td colspan="2">
											</td>
										</tr>
									</tbody>
									</table>;
};

export default Iredcheckout;