function Souvnear() {
  return (
   
      <div class="col-md-12">
		<div class="col-md-3">Sukhchain Singh</div>
		<div class="col-md-3">From</div>
		<div class="col-md-3">Ludhiana</div>
		<div class="col-md-3">Billa </div>
		<div class="col-md-3">Badmash</div>
	  </div>
    
  );
}

export default Souvnear;
