import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';

//import App from './App';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Reviews from './pages/Reviews';
import Iredcheckout from './pages/Iredcheckout';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Bookappointment from './pages/Bookappointment';
import NoPage from './pages/NoPage';
import Souvnear from './Souvnear.js';
import reportWebVitals from './reportWebVitals';


export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="reviews" element={<Reviews />} />
          <Route path="iredcheckout" element={<Iredcheckout />} />
          <Route path="contactus" element={<Contact />} />
          <Route path="privacypolicy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
          <Route path="bookappointment" element={<Bookappointment />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
