 import React, { useEffect, useState } from "react";
 import { Outlet, Link } from "react-router-dom";
import axios from "axios";

const Home = () => {
const cors = require('cors')
const getPostsData = () => {
  axios
  .get("https://i-red.co/api/indexapi")
  .then(data => console.log(data.data))
  .catch(error => console.log(error));
  };
 getPostsData();

  return  	<main id="main">
  <section className="pt-0">
  	
      <div className="row gy-4">
        <div className="col-md-12">
			<img src="assets/img/imgs/drajayitaed.jpg" className="d-none d-md-flex w-100" alt=""/>
			<img src="assets/img/imgs/drajayitaedmob.jpg" className="d-flex d-md-none w-100" alt=""/>
		</div>
		</div>

  </section>
  <section id="hero" className="d-flex align-items-center">


	   <div className="container">
      <div className="row gy-4">
        
        <div className="col-md-4 d-flex flex-column justify-content-center pt-5 align-items-start">
          <img src="assets/img/imgs/Dr.Ajayita-sign.svg" height="78" className=" " alt=""/>
          <h3 className="mt-2 text-center">BAMS, Gold Medalist, HP University, Shimla</h3>

        </div>
        <div className="col-md-4 d-flex flex-column justify-content-center">
          <h1 className="text-uppercase">Economic
            Times
            Punjab
            Icon of
            2022</h1>
        </div>
		<div className="col-md-4 hero-img">
          <img src="assets/img/imgs/dr.svg" className="img-fluid topmaindr" alt=""/>
        </div>

      </div>
    </div>

	</section>


    <section id="about" className="about">
      <div className="container">

        <div className="row justify-content-between">
          <div className="col-lg-4 d-flex align-items-center justify-content-center about-img col-md-4">
            <img src="assets/img/imgs/dr-second-img.png" className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-8 pt-5 pt-lg-0 d-flex align-items-center col-md-8">
            <div className="px-5 sec-bg d-flex align-items-center">
              <div className="">
                <h3 className="h3">Book a direct appointment with Dr. Ajayita</h3>
                <p className="p mb-4">Start your journey to happiness now & join over 100,000 happy people!</p>
                <a href="#" className="btn rounded-0 tratement-btn me-3">Dr. Ajayita Treatment</a>
                <a className="btn rounded-0 consult-btn" data-bs-toggle="modal" data-bs-target="#appointment-modal">Book Dr. Appointment</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>{/* End About Section */}

    {/* ======= Services Section ======= */}
    <section className="services bg-black py-5">
      <div className="container-fluid px-lg-5">

        <div className="row">
          <div className="col-md-4 col-lg-4">
            <div className="row justify-content-between">
              <div className="col-lg-4 d-flex align-items-center justify-content-center ">
                <img src="assets/img/imgs/dr-solo.svg" className="img-fluid" alt=""/>
              </div>
              <div className="col-lg-8 pt-5 pt-lg-0 ">
                <div className=" ">
                  <div className="text-center text-md-left">
                    <h3 className="h3 text-white">Want to ask a direct question to</h3>
                    <img src="assets/img/imgs/Dr.Ajayita-white-sign.svg" height="40" alt=""/>
                    <p className="p text-white mt-1 mb-0">Message her directly on Twitter, Instagram, Facebook or book a
                      Appointment via website chat</p>

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 text-center">
            <img src="assets/img/imgs/Frame_1.gif" width="79%" alt=""/>
          </div>

          <div className="col-md-4 col-lg-2 text-center">
            <div>
              <a  href="https://twitter.com/DoctorAjayita" target="_blank" className="btn rounded-0 ask-btn">Ask On twitter</a>
              <h2 className="text-white mt-2">1,47,000 +</h2>
              <p className="p text-white"><span>
                  <font color="#F2E28D">followers on Twitter.</font>
                </span><br/>
                Taking Ayurveda to the World.</p>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 text-center">
            <div>
              <a href="https://www.instagram.com/drajayita/" target="_blank" className="btn rounded-0 ask-btn">Ask On Instagram</a>
              <div className="text-center pt-3">
                <a href="#"><i className="bi bi-instagram"></i></a>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-lg-2 text-center">
            <div>
              <a target="_blank"  href="https://www.facebook.com/DrAjayita" className="btn rounded-0 btn-info  ask-btn">Ask On Facebook </a>
              <div className="text-center pt-3">
                <a href="#"><i className="bx bxl-facebook"></i></a>
                
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>{/* End Services Section */}

    {/* Got a Question? section */}
    <section className="got-ques">
      <div className="container py-4">
        <div className="text-center">
          <img src="assets/img/imgs/GotaQuestion.svg" alt=""/>
          <p className="p mb-4 text-white text-center mt-3">Need more details? Chat with our experts now!</p>
          <a href="https://wa.me/+919041593839/?text=Hello%20Team%20Jamun" className="btn rounded-0 WhatsApp-btn me-3"><i className="bi bi-whatsapp"></i> WhatsApp</a>
          <a href="https://tawk.to/chat/611df20f649e0a0a5cd1d803/1fdefj3te" className="btn rounded-0 consult-btn">LIve Chat</a>
        </div>
      </div>
    </section>
    {/* Got a Question? section */}

    {/* ======= Our Products Section ======= */}
    <section hidden id="team" className="team">
      <div className="container">

        <div className="section-title">
          <h2 className="text-uppercase">Our Products</h2>
        </div>

        <div className="product-slider swiper">
          <div className="swiper-wrapper align-items-center">
            <div className="swiper-slide">
              <div className="member ">
                <div className="social p-2">
                  <h3 className="h3 d-block">I-Red Oil (60 Days)</h3>
                  <img src="assets/img/imgs/p-1.png" className="img-fluid" alt=""/>
                  <a href="#" className="buy-nowbtn d-block">Buy Now <i className="bi bi-chevron-right"></i></a>
                </div>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>I-Red Oil (60 Days)</h4>
                    <span>Recommended 60 Days Course</span>
                    <p>Size 60 ML</p>
                    <a href="#" className="buy-nowbtn">Buy Now <i className="bi bi-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="member member-second">
                <div className="social p-2">
                  <h3 className="h3 d-block">I-Red Oil (60 Days)</h3>
                  <img src="assets/img/imgs/p-2.png" className="img-fluid" alt=""/>
                  <a href="#" className="buy-nowbtn d-block">Buy Now <i className="bi bi-chevron-right"></i></a>
                </div>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>I-Red Oil (60 Days)</h4>
                    <span>Recommended 60 Days Course</span>
                    <p>Size 60 ML</p>
                    <a href="#" className="buy-nowbtn">Buy Now <i className="bi bi-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="member">
                <div className="social p-2">
                  <h3 className="h3 d-block">I-Red Oil (60 Days)</h3>
                  <img src="assets/img/imgs/p-3.png" className="img-fluid" alt=""/>
                  <a href="#" className="buy-nowbtn d-block">Buy Now <i className="bi bi-chevron-right"></i></a>
                </div>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>I-Red Oil (60 Days)</h4>
                    <span>Recommended 60 Days Course</span>
                    <p>Size 60 ML</p>
                    <a href="#" className="buy-nowbtn">Buy Now <i className="bi bi-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div className="member member-second">
                <div className="social p-2">
                  <h3 className="h3 d-block">I-Red Oil (60 Days)</h3>
                  <img src="assets/img/imgs/p-4.png" className="img-fluid" alt=""/>
                  <a href="#" className="buy-nowbtn d-block">Buy Now <i className="bi bi-chevron-right"></i></a>
                </div>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>I-Red Oil (60 Days)</h4>
                    <span>Recommended 60 Days Course</span>
                    <p>Size 60 ML</p>
                    <a href="#" className="buy-nowbtn">Buy Now <i className="bi bi-chevron-right"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </section>{/* End Our Products Section */}

    {/* Our Reviews section */}
    <section id="reviews" className="team d-flex align-items-end">
      <div className="container">

        <div className="section-title">
          <h2 className="text-uppercase">Our Reviews</h2>
        </div>

        <div className="d-flex align-center">

          <div className="row">
			            
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r185.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 29-06-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r184.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 21-06-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r183.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 21-06-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r182.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 14-05-2023</span>
                     </div>
                  </div>

               </div>
			   			
			
        
						               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r181.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 31-05-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r180.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 20-04-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r179.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 16-05-2023</span>
                     </div>
                  </div>

               </div>
			   			               
               <div className="col-md-4 col-lg-3 mb-4">
                  <div className="reviewmain-div">
                     <img src="https://www.jamun.com/public/img/desktop_review/r178.jpg" alt="I-Red Review"/>
                     <div className="d-flex reviewdate">
                        <i className="fa fa-calendar-o mr-2"></i>
                        <span>Added On : 16-05-2023</span>
                     </div>
                  </div>

               </div>

            </div>

        </div>

      </div>
    </section>
    {/* end of Our Reviews section */}

    {/* ======= About Section ======= */}
    <section className="about">
      <div className="container">

        <div className="row justify-content-between">
          <div className="col-lg-4 d-flex align-items-center justify-content-center about-img col-md-4">
            <img src="assets/img/imgs/dr-second-img.png" className="img-fluid" alt=""/>
          </div>
          <div className="col-lg-8 pt-lg-5 pt-lg-0 d-flex align-items-center col-md-8">
            <div className="px-5 dr-info sec-bg d-flex align-items-center bg-white">
              <div className="">
                <p className="p mb-0">I bring you products that I have researched & perfected in my clinic over the past</p>
                <h2>23+ <span>Years.</span></h2>
                <p className="p mb-2">All these products are based on Pure Ancient Ayurvedic texts researched & written by
                  our Rishis.</p>
                <img src="assets/img/imgs/red-sign.svg" height="80" alt=""/>
                <h4 className="h4  mt-3">Economic Times Punjab Icon of 2022</h4>
                <h5 className="h5">BAMS, Gold Medalist, HP University, Shimla</h5>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>{/* End About Section */}

    {/* product slider section */}
    <section hidden id="p-slider">
      <div className="container-fluid">
        <div>
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="product-div">
                  <div className="row">
                    <div className="col-md-7 d-flex align-items-center w-100">
                      <div>
                        <h2>I-WONDER
                          COMBO HAIR OIL</h2>
                        <p>Promotes Hair Growth
                          Stops Hair Thinning & Hair Loss
                          Tough Dandruff
                          Premature Greying</p>
                        <a href="#" className="btn rounded-0 buy-now"> Buy Now <i className="bi bi-chevron-right"></i></a>
                      </div>
                      <div className="col-md-5 text-center position-relative">
                        <img src="./assets/img/imgs/sliderp-2.png" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="product-div product-red-div">
                  <div className="row">
                    <div className="col-md-7 d-flex align-items-center w-100">
                      <div>
                        <h2>I-WONDER
                          COMBO HAIR OIL</h2>
                        <p>Promotes Hair Growth
                          Stops Hair Thinning & Hair Loss
                          Tough Dandruff
                          Premature Greying</p>
                        <a href="#" className="btn rounded-0 buy-now"> Buy Now <i className="bi bi-chevron-right"></i></a>
                      </div>
                      <div className="col-md-5 text-center position-relative">
                        <img src="./assets/img/imgs/sliderp-1.png" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="product-div product-green-div">
                  <div className="row">
                    <div className="col-md-7 d-flex align-items-center w-100">
                      <div>
                        <h2>I-WONDER
                          COMBO HAIR OIL</h2>
                        <p>Promotes Hair Growth
                          Stops Hair Thinning & Hair Loss
                          Tough Dandruff
                          Premature Greying</p>
                        <a href="#" className="btn rounded-0 buy-now"> Buy Now <i className="bi bi-chevron-right"></i></a>
                      </div>
                      <div className="col-md-5 text-center position-relative">
                        <img src="./assets/img/imgs/sliderp-3.png" alt=""/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
    {/* end of product slider section */}


 

    {/* certified section */}
    <section className="got-ques certified">
      <div className="container py-4">
        <div className="text-center">
          <img className="mx-2" src="assets/img/imgs/certifird-3.svg" alt=""/>
          <img className="mx-2" src="assets/img/imgs/certifird-1.svg" alt=""/>
          <img className="mx-2" src="assets/img/imgs/certifird-2.svg" alt=""/>
        </div>
      </div>
    </section>
    {/* end of certified section */}
   {/* ======= Clients Section ======= */}
    <section id="clients" className="clients team">
      <div className="container">

        <div className="section-title">
          <h2>As Featured in</h2>

        </div>

        <div className="clients-slider text-center">
          <div className="row">
            <div className="col-lg-2"><img src="assets/img/imgs/jagran.svg" className="img-fluid  testti" alt=""/></div>
            <div className="col-lg-2"><img src="assets/img/imgs/punjab-kesari.svg" className="img-fluid testti" alt=""/></div>
            <div className="col-lg-2"><img src="assets/img/imgs/Newsmirror.svg" className="img-fluid testti" alt=""/></div>
            <div className="col-lg-2"><img src="assets/img/imgs/Newsnetnow.svg" className="img-fluid testti" alt=""/></div>
            <div className="col-lg-2"><img src="assets/img/imgs/Open.svg" className="img-fluid testti" alt=""/></div>
            <div className="col-lg-2"><img src="assets/img/imgs/Pioneer.svg" className="img-fluid testti" alt=""/></div>
          </div>

        </div>

      </div>
    </section>{/* End Clients Section */}

    {/* blogs section
    <section className="team mt-5" id="blogs">
      <div className="container">

        <div className="section-title">
          <h2 className="text-uppercase">Read Our Blogs</h2>
        </div>
        <div className="row py-lg-3">
          <div className="col-md-8">
            <div className="single-blog mb-4">
              <img src="assets/img/imgs/blog1.png" width="100%" alt=""/>
              <h2 className="mt-4">Collaborate and celebrate in tastefully designed</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not</p>
              <a href="#" className="btn p-0 rounded-0 bg-transparent">Read more <i className="bi bi-chevron-right"></i></a>
            </div>
          </div>
          <div className="col-md-4 side-blog">
            <div className="single-blog mb-4 ">
              <img src="assets/img/imgs/blog2.png" height="138" alt=""/>
              <h2>Collaborate and celebrate in tastefully designed</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not</p>
              <a href="#" className="btn p-0 rounded-0 bg-transparent">Read more <i className="bi bi-chevron-right"></i></a>
            </div>
            <div className="single-blog mb-4">
              <img src="assets/img/imgs/blog3.png" height="138" alt=""/>
              <h2>Collaborate and celebrate in tastefully designed</h2>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
                scrambled it to make a type specimen book. It has survived not</p>
              <a href="#" className="btn p-0 rounded-0 bg-transparent">Read more <i className="bi bi-chevron-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>*/}
	<div className="modal p-2" id="appointment-modal">
               <div className="modal-dialog modal-lg modal-dialog-centered">
                  <div className="modal-content p-1">
                     <div className="modalborder">
                        <div className="p-2">
                           <button type="button" className="close" data-bs-dismiss="modal">&times;</button>
                           <div className="dis-mainmodal">
                            
                             
                              <div className="modal-body">
									<div className="col-lg-12 pt-5 pt-lg-0 w-100 align-items-center">
									<div className="px-0 d-flex align-items-center text-center">
									  <div className="">
										<h3 className="h3doc">How you can consult Dr. Ajayita?</h3>
										<p className="p mb-4"><strong>Clinic Visit: </strong>
Dr. Ajayita is available for consultation in her clinic from Monday to Saturday at the following address:<br/>
<strong>Aclinic, SCO 71FF, Sector 30-C, Chandigarh. Ph: 9041593839</strong></p>
										<p className="p mb-4"><strong>Online Consultation: </strong>
										You can book a telephonic consultation with Dr. Ajayita by clicking below.
										</p>
										<a href="https://www.jamun.com/bookconsultation" className="btn rounded-0 tratement-btn me-3">Book Dr. Appointment</a>
										
										</div>
									</div>
								  </div>
                              </div>
							  </div>
                        </div>
						   
                     </div>
                  </div>
               </div>
            </div> 
  </main>;
};

export default Home;