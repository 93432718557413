import { useState, useEffect } from "react"
import axios from "axios"; 


const Contact  = () => {	
	const cors = require('cors')
	  useEffect(() => {
		
	  });
	    const [isActive, setIsActive] = useState({
			id: 'd-none',
		  })
		  useEffect(() => {
			console.log(isActive)
		  }, [isActive])
		  const hideShowDiv = (ee) => {
			setIsActive({
			  id: ee,
			})
		  }
	
	const handleSubmit = async(e) => {
    // Prevent the default submit and page reload
    e.preventDefault()
	hideShowDiv('d-block')
    // Handle validations
    await axios.post("https://i-red.co/api/contactus_action",{ cname, cemail,cphone,cmessage })
			.then(response => {
        console.log(response)
		setRmessage(response.data.message);
		hideShowDiv('d-none')
		//setRespons(response);
        // Handle response
      })
	  
  }

  const [cemail, setEmail] = useState()
  const [response, setResponse] = useState()
  const [cname, setName] = useState()
  const [cmessage, setMessage] = useState()
  const [cphone, setPhone] = useState()
  const [rmessage, setRmessage] = useState()
  
  return  (<><title>Any Questions or Quarry | Contact Our Team Today</title>

	
	<meta name="title" content="Any Questions or Quarry | Contact Our Team Today" />
    <meta name="description" content="If you have any questions or Quarry regarding jamun products and services please visit our website contact us page.  "/>
    
	
	<meta name="twitter:card" content="summary_large_image" /> 
	<meta name="twitter:site" content="@ayurveda_i" />
	<meta name="twitter:title" content="Any Questions or Quarry | Contact Our Team Today" />
	<meta name="twitter:description" content="If you have any questions or Quarry regarding jamun products and services please visit our website contact us page.  "/>
	<meta name="twitter:image" content="{{ url('public/img/thubnail.jpg?v=1') }}"/>
	<script src="https://www.google.com/recaptcha/api.js" async defer></script>


      <main id="main" className="py-0 py-md-0 my-md-0 mt-0">
		<div className={isActive.id === 'd-block' ? `se-pre-con d-block` : 'se-pre-con d-none'}></div>

         <section className="mt-md-5 pt-3 pb-4 mb-3 blog-sec">
            <div className="container">
               <div className="overivew-main pt-2">
                  <h1>Contact Us</h1>
               </div>
            </div>
         </section>


         <section className="p-0">
            <div className="container">
               <div className="blog-detail-sub">
                  <img src="https://www.jamun.com/public/newjamun/img/contact-us.png" className="w-100" alt="couple image "/>
               </div>
            </div>
         </section>


         <section className="mt-md-5 pt-3 pb-4 term-bg-main bulk-purchase-main">
        <div className="container">


         <div className="row">
			
            <div className="col-md-10 col-lg-11 m-auto">

               <div className="row py-md-5 py-4">
                
                  <div className="col-md-12">
                      <div className="contact-details p-3 p-md-3 p-lg-4">
                       <p className="mb-4 d-flex align-items-center"> <i className="bi bi-mobile-phone mr-3"></i> Phone – 0172-2650793, +919041593839 (7 AM to 1 AM)</p> 
                       <p className="mb-4 d-flex align-items-center"> <i  className="bi bi-whatsapp mr-3"></i> <a href="https://wa.me/+919041593839/?text=Hello Team Jamun" className="btn ordernowbtn"><i className="bi bi-whatsapp"></i>&nbsp;&nbsp; WhatsApp</a> &nbsp;&nbsp;</p> 
                       <p className="mb-4 d-flex align-items-center"> <i className="bi bi-envelope-o "></i> Email – shipping@ayamhealth.com</p> 
                       <p className="mb-0 d-flex align-items-center"> <img src="https://www.jamun.com/public/newjamun/img/loc-icon.png" width="18px" className="mr-3" alt="address"/>Address – A Clinic, First Floor, SCO 71, Sector 30, Chandigarh</p> 
                      </div>
                  </div>
               </div>


            <div className="pt-0 pt-md-5 ">
             <div className="text-md-left contact-secsub">
            
              <p className="mb-3 mb-md-5 text-md-left">We'd love to hear from you, please drop us a line if you've any query</p>
            </div>

    <div className="bulkpurchase-form">
     <form action="" id="login" method="post" onSubmit={handleSubmit}>
		
         <div className="row">  
             <div className="col-md-6">  
                 <div className="form-group mb-4">  
                  <label for="">Name:*</label>
                     <input type="text"
						name="cname"
						id="cname"
						value={cname}
						className="form-control rounded-0"
						onChange={e => setName(e.target.value)}
					  />
			
                 </div>  
             </div>  
             <div className="col-md-6">  

               <div className="form-group mb-4"> 
                  <label for="">Phone</label> 
                     <input type="tel"
						name="cphone"
						id="cphone"
						value={cphone}
						className="form-control rounded-0"
						onChange={e => setPhone(e.target.value)}
					  />  
                 </div>      
             </div>  
             <div className="col-md-12">  
               <div className="form-group mb-4"> 
                  <label for="">Email:*</label> 
                  <input type="email" 
						name="cemail"
						id="cemail"
						value={cemail}
						 className="form-control rounded-0"
						onChange={e => setEmail(e.target.value)}
					  />  
              </div>   
             </div>  
             <div className="col-12">  
                 <div className="form-group mb-4">  
                  <label for="">Message</label>
             <textarea rows="3" name="cmessage" required onChange={e => setMessage(e.target.value)} className="form-control rounded-0">  </textarea>  
                 </div>  
             </div>  
             <div className="col-12">
               <div className="">
                  <div className="g-recaptcha" data-sitekey="6Lexg30eAAAAAGrrFRy-NHK3vXeteZn3Eb01CYJ0"></div>
                </div>
             </div>
         </div>  
         <div className="text-center mt-3">  
            <button name="contactus" type="submit" className="btn text-capitalize buy-nowbtn mt-4">Submit</button>
         </div>  
     </form>  
	 { rmessage ? <div className="alert alert-success mt-4">Data has been submitted	</div>: null }
    </div>
              
            </div>
           </div>
          </div>
       </div>
         </section>

         
     
      </main>; 
	  </>)


};

export default Contact;